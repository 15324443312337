@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

*{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    
	box-sizing: border-box;
}
.banner {
  width: 100%;
  min-height: 100vh; /* Cambiar height por min-height */
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(ImagenInicio.jpg);
  background-size: cover;
  background-position: center;
  display: flex; /* Alinear contenido con flexbox */
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.header{
    width: 100vw;
    height: 80px;
    /* background: #009688; */
}
.navbar{
    width: 85%;
    margin: auto;
    padding:  0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    width: 50px;
    cursor: pointer;
}
.navbar ul li {
  list-style: none;
  display: inline-block;
  margin: 0 10px; /* Reducimos el margen entre los elementos */
  position: relative;
}

.navbar ul li a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9rem; /* Reducimos ligeramente el tamaño de la fuente */
}
.navbar ul li::after{
    content: "";
    height: 3px;
    width: 0;
    background: #009688;
    position: absolute;
    left: 0;
    bottom: -10px;
    transition: 0.5s;
}
.navbar ul li:hover:after{
    width: 90%;
}
.content{
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: #fff;
}
.content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.content p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
}
button{
    width: 200px;
    height: 50px;
    padding: 10px 0;
    text-align: center;
    margin: 20px 10px;
    border-radius: 25px;
    font-weight: bold;
    border: 2px solid #009688;
    background: transparent;
    color: #fff;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
span{
    background: #009688;
    height: 100%;
    width: 0;
    border-radius: 25px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: 0.5s;
}
button:hover span{
    width: 100%;
}
button:hover{
    border: none;
}
.contacto{
    /* background-color: #6C4591 !important; */
    color: #c8c8cf;
    padding-top: 15px;
    padding-bottom: 100px;
  }
.clientes img{
    width: 150px;
    height: 150px;
    /* cursor: pointer; */
    border-radius: 8px;
    background: transparent;
  }
  .btn-success {
    background: #00f2c3;
    background-image: -webkit-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
    background-image: -o-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
    background-image: -moz-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
    background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #00f2c3;
    transition: all 0.15s ease;
    box-shadow: none;
    color: #fff; }
    .btn-success.animation-on-hover:hover {
      background-position: bottom left;
      transition: 0.3s ease-in-out; }
    .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success:active:focus, .btn-success:active:hover, .btn-success.active:focus, .btn-success.active:hover {
      background-color: #0098f0 !important;
      background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3) !important;
      background-image: -webkit-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3) !important;
      background-image: -o-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3) !important;
      background-image: -moz-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3) !important;
      color: #fff;
      box-shadow: none; }
    .btn-success:active {
      box-shadow: none !important;
      transform: translateY(1px) !important;
      transition: all .15s ease; }
    .btn-success:not([data-action]):hover {
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
      transform: translateY(-1px);
      -webkit-transform: translateY(-1px); }
    .btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success:disabled, .btn-success:disabled:hover, .btn-success:disabled:focus, .btn-success:disabled.focus, .btn-success:disabled:active, .btn-success:disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
    fieldset[disabled] .btn-success,
    fieldset[disabled] .btn-success:hover,
    fieldset[disabled] .btn-success:focus,
    fieldset[disabled] .btn-success.focus,
    fieldset[disabled] .btn-success:active,
    fieldset[disabled] .btn-success.active {
      background-color: #00f2c3;
      border-color: #00f2c3; }
    .btn-success.btn-simple {
      color: #00f2c3;
      border-color: #00f2c3;
      background: transparent; }
      .btn-success.btn-simple:hover, .btn-success.btn-simple:focus, .btn-success.btn-simple:active, .btn-success.btn-simple:not(:disabled):not(.disabled):active {
        color: #00f2c3;
        border-color: #00f2c3;
        background-color: transparent !important;
        background-image: none !important;
        box-shadow: none; }
      .btn-success.btn-simple.active {
        border-color: #00f2c3 !important; }
        .btn-success.btn-simple.active:hover, .btn-success.btn-simple.active:focus, .btn-success.btn-simple.active:active, .btn-success.btn-simple.active:not(:disabled):not(.disabled):active {
          color: #fff;
          border-color: #00f2c3;
          background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3) !important;
          background-image: -webkit-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3) !important;
          background-image: -o-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3) !important;
          background-image: -moz-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3) !important;
          background-color: #0098f0 !important;
          box-shadow: none; }
    .btn-success.btn-link {
      color: #00f2c3; }
      .btn-success.btn-link:hover, .btn-success.btn-link:focus, .btn-success.btn-link:active {
        background-color: transparent !important;
        background-image: none !important;
        color: #fff !important;
        text-decoration: none;
        box-shadow: none; }
  

.botonesMenu{
    width: 230px;
    height: 180px;
    border-radius: 5px;
    outline: auto;
}

:root {
	--mainColor: #3b3939;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	background-color: var(--mainColor);
	color: var(--textColor);
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}


header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
      visibility: visible;
      opacity: 1;
  }

  header nav {
      position: fixed;
      top: -100vh;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      background-color: var(--mainColor);
      transition: 1s;
  }

  header .responsive_nav {
      transform: translateY(100vh);
  }

  nav .nav-close-btn {
      position: absolute;
      top: 2rem;
      right: 2rem;
  }

  nav a {
      font-size: 1.5rem;
  }

  /* Ajustes para el menú en pantallas pequeñas */
  .navbar ul {
      flex-direction: column; /* Los elementos del menú se disponen en columna */
      text-align: center;
  }

  .navbar ul li {
      margin: 10px 0; /* Espacio entre los elementos del menú en pantallas pequeñas */
  }

  .navbar ul li a {
      font-size: 1.2rem; /* Tamaño de fuente ligeramente más pequeño en dispositivos pequeños */
  }
}


.fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;  /* Posicionar el widget en la esquina inferior derecha */
  z-index: 100;
}

.whatsapp-icon {
  font-size: 50px;  /* Ajusta el tamaño del ícono de WhatsApp */
  color: #25d366;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.footer h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.footer-form {
  max-width: 600px;
  margin: 0 auto;
}

.footer input,
.footer textarea,
.footer select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.footer button {
  background-color: #ff5722;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.footer button:hover {
  background-color: #ff784e;
}

.footer-info {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #bbb;
}

.waves {
  text-align: left; /* Alinea los textos a la izquierda */
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.waves ul {
  list-style-type: none;
  padding: 0;
}

.waves ul li {
  list-style: none;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
  padding: 15px;
  border-left: 4px solid #00f2c3;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 1.6;
}


.content {
  margin-bottom: 4rem; 
  text-align: center;
  padding: 2rem; /* Añadir espacio interior */
  color: #fff;
}

.footer {
  clear: both;
  position: relative; /* Asegura que el footer siempre se quede debajo del contenido */
}

button {
  transition: all 0.3s ease;
}


.content {
  padding: 2rem;
  text-align: center;
}
html {
  scroll-behavior: smooth;
}

